
//DEV
 //export const CUSTOMER_API = 'https://f8oju35w80.execute-api.sa-east-1.amazonaws.com/Dev/v1/'
 //export const API_KEY = '85BhX5l8WJ7OM1GQoS1eG1suT38XjgM487OZ2uTK'
//HOM
export const CUSTOMER_API = 'https://0x2pggtb76.execute-api.us-east-1.amazonaws.com/QA/v1/'
export const API_KEY = 'Bs8A6HWUc19hapKslrZYP4A32oQUegtj91912Cjj' 


//PROD
//export const CUSTOMER_API = 'https://ln8f56ouud.execute-api.sa-east-1.amazonaws.com/Prod/v1/'
//export const API_KEY = 'BtWrQPI1vz6qhpHonUJSr1km5zdAqZcP7DjMfp2G'
