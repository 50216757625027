import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { CUSTOMER_API } from '../../app.api';
import { Prospect } from '../models/prospect.model';
import { DataTransferModel } from '../models/app/data-transfer.model';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {

  private dataTransfer: DataTransferModel;
  private transfer = new BehaviorSubject<DataTransferModel>(this.dataTransfer);

  constructor(private http: HttpClient) {}
  
  save(prospect: Prospect): Observable<any> {
    return this.http.post<Prospect>(`${CUSTOMER_API}prospects-wifi`, prospect);
  }

  setDataTransfer(prospect : Prospect, firstAccess: boolean) : void {
    let dataTransfer = new DataTransferModel(prospect, firstAccess);
    this.transfer.next(dataTransfer);
  }

  getDataTransfer(): Observable<DataTransferModel> {
    return this.transfer.asObservable();
  }
}