import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from 'rxjs/operators';
import { API_KEY } from '../../app.api';
import { ProspectService } from '../services/prospect.service';

@Injectable()
export class HttpReqInterceptor implements HttpInterceptor {

  private cpf: string;

  constructor(private spinner: NgxSpinnerService, private prospectService: ProspectService) {
    this.prospectService.getDataTransfer().subscribe(data => {
      if (data) {
        this.cpf = data.prospect.cpf;
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    let confReq: any;

    confReq = request.clone({
      headers: request.headers.set('Content-type', 'application/json')
        .set('X-API-KEY', API_KEY)
    });

    return next.handle(confReq).pipe(
      finalize(() => {
        setTimeout(() => {
          this.spinner.hide();
        }, 3000);
      })
    );
  }
}